import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import rootReducer from './store/root_reducers';
import rootMiddleware, { sagaMiddleware, combineSagas } from './store/root_middleware';

import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';

import './index.scss';
import './assets/fontawesome/all.css';
import App from './App';

// import ReactGA from 'react-ga';

// if (process.env.NODE_ENV === "production") {

//   ReactGA.initialize(
//     [
//       {
//         trackingId: 'UA-151049635-1',
//       },
//       {
//         trackingId: 'AW-698878230',
//       }
//     ]
//   );
// }

const store = createStore(rootReducer, rootMiddleware);

sagaMiddleware.run(combineSagas);

ReactDOM.render(

  <Provider store={store}>
    <BreadcrumbsProvider>
      <App />
    </BreadcrumbsProvider>
  </Provider>,

  document.getElementById('root')
  
);