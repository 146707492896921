import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import { routesArray } from './routing';
import ScrollToTop from './hoc/scroll-top';
// import GaAnalytics from './hoc/ga-analytics';
import PageNotFound from './components/page_not_found';
import HeaderBar from './components/header_bar';
import Footer from './components/footer';
import Auth from './components/auth/auth';
import AddSubscription from './components/add_subscription/add_subscription';
import PaymentSubscriptionNotify from './components/add_subscription/payment_subscription_notify';

import * as actionsCreatorAuth from './store/root_actions/auth';
import './App.scss';
import { Helmet } from 'react-helmet';

class App extends Component {

  static propTypes = {
		isAuthModalOpen: PropTypes.bool.isRequired,
		isAddSubscriptionModalOpen: PropTypes.bool.isRequired,
    isPaymentModalStateOpen: PropTypes.bool.isRequired,
  }

  componentDidMount() {

    const data = JSON.parse(localStorage.getItem("easyMath"));

    if (data !== null) {
      this.props.authRefresh();
    }
  }

  render() {
    const { isAuthModalOpen, isAddSubscriptionModalOpen, isPaymentModalStateOpen } = this.props;

    return (

      <Router>
        <Helmet>
          <meta name="keywords" content="easymath, math, състезания, видео уроци, упражнения, тестове, видео уроци, уроци, математика, подготовка по математика, задачи" />
        </Helmet>

        <ScrollToTop />
        {
          // process.env.NODE_ENV === "production" &&
          // <GaAnalytics />
        }
        <div className="wrapper">
          {/* Header bar is wrapped in a route in order to get access to the "location match history" params */}
          <Route render={(props) => <HeaderBar {...props} />}/>
        </div>

        {
          isAuthModalOpen
          ? <Auth />
          : null
        }

        {
          isAddSubscriptionModalOpen
          ? <AddSubscription />
          : null
        }

        {
          isPaymentModalStateOpen
          ? <PaymentSubscriptionNotify />
          : null
        }

        <Switch>
          {
            routesArray.map((item, index) => <Route key={index} exact={item.exact} path={item.path} component={item.mainComponent} />)
          }
          <Route render={() => <PageNotFound />} />
        </Switch>

        <div className="wrapper">
          {/* Footer bar is wrapped in a route in order to get access to the "location match history" params */}
          <Route render={(props) => <Footer {...props}/>}/>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Разбрах!"
          cookieName="CookieConsent"
          style={{ background: "#015286", color: "#fff", fontWeight: "700", borderTop: "1px solid #ffc534" }}
          buttonStyle={{ color: "#015286", fontSize: "16px", background: "#ffc534", padding: "12px 15px" }}
          expires={365}
        >
          С използването на сайта вие приемате, че използваме „бисквитки" за подобряване на преживяването, персонализиране на съдържанието и анализиране на трафика.{" "}
          <span style={{ fontSize: "14px" }}> Вижте нашите {" "}
            <NavLink to="/general-terms" style={{ color: "#ffc534", textDecoration: "underline" }}>
              Общи условия
            </NavLink>
            {" "} и {" "} 
            <NavLink to="/private-data" style={{ color: "#ffc534", textDecoration: "underline" }}>
              Политика за личните данни.
            </NavLink>
          </span>
        </CookieConsent>
      </Router>

    )

  }
}

function mapStateToProps({ authedUser, addSubscription }) {
  return {
    isAuthModalOpen: authedUser.authModalState,
    isAddSubscriptionModalOpen: addSubscription.subscriptionModalState,
    isPaymentModalStateOpen: addSubscription.paymentModalState
  };
}

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators({
      ...actionsCreatorAuth
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(App);