import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import './add_subscription.scss';
// import ReactGA from 'react-ga';

import * as actionsCreatorSubscription from "./actions";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";
import * as actionsAccountStats from '../account_stats/actions';
import * as actionsCreatorAuth from "../../store/root_actions/auth";
import * as actionsCreatorModal from "../shared/ui/modal/actions";

import SubscriptionBody from "./subscription_body";
import SubsriptionFooter from "./subscription_footer";
import InvoiceHeader from "./invoice_header";
import InvoiceLegal from "./invoice_legal";
import InvoiceIndividual from "./invoice_individual";
import Modal from '../shared/ui/modal/modal';
import { subscriptionsInitState, legalInitState, individualInitState, individualValidateInitState, legalValidateInitState,
  individualUpdateInitState, legalUpdateInitState } from '../../constants/add_subscriptions';
import { isInputValid, checkValidationFields, checkAccessTokenExpirationTime, isObjectEmpty } from "../../utils/helpers/common";

import Notify from "../shared/ui/notifications/notify_template";
import Spinner from "../shared/ui/spinner/spinner";

class AddSubscription extends Component {

  static propTypes = {
    isSubscriptionOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    userId: PropTypes.number.isRequired,
    accessTokenExprirationTime: PropTypes.number.isRequired,
    notifySubscriptions: PropTypes.object.isRequired,
    successPayment: PropTypes.object.isRequired,
    refreshingTokenInPorgress: PropTypes.bool.isRequired,
    isSubscriptionNotPaid: PropTypes.bool.isRequired,
    percentPrice: PropTypes.number.isRequired,
    promoCode: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      ...subscriptionsInitState,
      isSubmited: false
    };
  }

  componentDidMount() {

    const isAccessTokenExpired = checkAccessTokenExpirationTime(this.props.accessTokenExprirationTime);
    document.body.style.overflow = 'hidden';

    if (isAccessTokenExpired) {
      this.props.authRefresh('subscription', null);
      this.resetState();
    } else {
      this.props.subscriptionsInit();
      this.resetState();
    }

  }

  componentWillUnmount() {

    document.body.style.overflow = 'unset';
    this.props.toggleAccountStatus(false);

    if (this.props.isSubscriptionNotPaid) {
      this.props.modalSubscriptionPaid(false);
    }

    if (this.props.notifySubscriptions.active) {
      this.props.notifyMessageReset("Subscriptions");
    }

    if (!isObjectEmpty(this.props.successPayment)) {
      this.props.resetAddSubscriptions();
    }

    if (this.props.promoCode !== '') {
      this.props.subscriptionDiscountReset();
    }
  }

  componentDidUpdate() {

    if (!this.props.refreshingTokenInPorgress && this.state.isSubmited && !this.props.subscriptionModalState) {
      this.setState({
        ...subscriptionsInitState,
        isSubmited: false
      });

      if (this.props.promoCode !== '') {
        this.props.subscriptionDiscountReset();
        this.props.notifyMessageReset("SubscriptionDiscount");
      }
    }

    if (this.props.isDiscountSuccessLoaded) {
      this.props.isDiscountLoadedSuccess(false);
      this.handleDiscountPrice();
    }

  }

  handleClose = () => {
    this.props.addSubscriptionModalState(false);
  }

  handleFocus = (e, type) => {
    const currentName = e.currentTarget.name;
    const newFocusData = {
      ...this.state[`${type}Focus`]
    };
    newFocusData[currentName] = true;

    this.setState({
      [`${type}Focus`]: newFocusData
    })
  }

  handleFocusOut = (e, type) => {
    const currentName = e.currentTarget.name;
    const newFocusData = {
      ...this.state[`${type}Focus`]
    };
    newFocusData[currentName] = false;

    this.setState({
      [`${type}Focus`]: newFocusData
    })
  }

  handleChange = (e, type) => {
    let newFormData = { ...this.state[type] };
    let newValidateFormData = { ...this.state[`${type}Validate`] };
    let newUpdateFormData = { ...this.state[`${type}Update`] };

    let isValid;
    const target = e.currentTarget;

    const isChecked = target.checked;
    const targetValue = target.value;
    const targetName = target.name;
    const isSelected = target.selectedIndex;

    targetName !== "invoice" ? (newFormData[targetName] = targetValue) : (newFormData[targetName] = isChecked);

    if (targetName !== 'selectInvoice' && targetName !== 'invoice') {

      isValid = isInputValid(targetValue, `${targetName.toUpperCase()}`, null, isSelected, isChecked);
      newValidateFormData[targetName] = isValid;
    }

    newUpdateFormData[targetName] = true;

    if (targetName === 'subscriptionId') {
      const selectedSubsriptionPrice = Number(target[isSelected].dataset.price);
      const selectedSubsriptionContest = target[isSelected].dataset.contest;

      // if (process.env.NODE_ENV === "production") {
      //   ReactGA.event({
      //     category: 'conversion',
      //     action: 'add',
      //     label: `add-${target[isSelected].text}`
      //   })
      // }

      if (this.state.percentDiscountPrice !== 0) {

        this.props.subscriptionDiscountReset();
        this.props.notifyMessageReset("SubscriptionDiscount");

        this.setState({
          subsriptionPrice: selectedSubsriptionPrice,
          isContestSelected: selectedSubsriptionContest,
          percentDiscountPrice: 0,

        })
      } else {
        this.setState({
          subsriptionPrice: selectedSubsriptionPrice,
          isContestSelected: selectedSubsriptionContest
        })
      }
    }

    if (targetName === "selectInvoice") {
      const selectedInvoiceId = Number(target.dataset.id);

      if (targetValue === 'legal') {

        this.setState({
          selectedInvoiceId: selectedInvoiceId,
          individual: individualInitState,
          [type]: newFormData,
          individualValidate: individualValidateInitState,
          individualUpdate: individualUpdateInitState
        })
      } else {

        this.setState({
          selectedInvoiceId: selectedInvoiceId,
          legal: legalInitState,
          [type]: newFormData,
          legalValidate: legalValidateInitState,
          legalUpdate: legalUpdateInitState
        })
      }
    } else {
      this.setState({
        [type]: newFormData,
        [`${type}Validate`]: newValidateFormData,
        [`${type}Update`]: newUpdateFormData
      })
    }

    if (targetName === "level") {
      const selectedSubsriptionContestId = target[isSelected].dataset.contestid;

      this.setState({
        competitionId: Number(selectedSubsriptionContestId)
      })
    }

  }

  onSubmitHandler = () => {
    const { accessTokenExprirationTime } = this.props;
    const data = this.prepareDataPayment();
    
    this.setState({
      isSubmited: true
    })

    // if (process.env.NODE_ENV === "production") {
    //   ReactGA.event({
    //     category: 'conversion',
    //     action: 'payment',
    //     label: `pay-${data.userId}-${data.subscriptionId}-${Math.floor(Math.random() * Math.floor(3000))}`
    //   })
    // }

    const isAccessTokenExpired = checkAccessTokenExpirationTime(accessTokenExprirationTime);
      if (isAccessTokenExpired) {
        this.props.authRefresh('subscriptionPayment', data);
      } else {
        this.props.deliverySubscriptionPayment(data);
      }
  }

  resetState = () => {
    if (!this.props.subscriptionModalState) {
      this.setState({
        ...subscriptionsInitState
      });
    }
  }

  prepareDataPayment = () => {
    const { userId, promoCode } = this.props;
    const { subscription, legal, individual, isContestSelected, contest, competitionId } = this.state;

    let data = {}
    let requiredPayload = {}

    if (isContestSelected === 'true') {
      requiredPayload = {
        userId,
        subscriptionId: subscription.subscriptionId,
        payment: subscription.payment,
        invoice: Number(subscription.invoice),
        selectedInvoiceId: this.state.selectedInvoiceId,
        isCompetition: isContestSelected,
        competitionId: competitionId,
        childNames: contest.name,
        childClass: contest.level,
        childSchool: contest.school,
        childCity: contest.city,
        parentNames: contest.parent,
        competitionPhone: contest.contestPhone
      }
    } else {
      requiredPayload = {
        userId,
        subscriptionId: subscription.subscriptionId,
        payment: subscription.payment,
        invoice: Number(subscription.invoice),
        selectedInvoiceId: this.state.selectedInvoiceId
      }
    }

    if (promoCode !== '') {

      requiredPayload.promoCode = promoCode;

      data = {
        ...requiredPayload
      }
    } else {
      data = {
        ...requiredPayload
      }
    }

    if (subscription.invoice) {
      if (subscription.selectInvoice === 'legal') {
        return data = {
          ...requiredPayload,
          company: legal.company,
          companyId: legal.companyId,
          vatNumber: legal.vatNumber,
          address: legal.address,
          city: legal.city,
          personInCharge: legal.personInCharge,
          recipient: legal.recipient
        }
      } else {
        return data = {
          ...requiredPayload,
          name: individual.name,
          address: individual.address,
          city: individual.city
        }
      }
    }
    return data;
  }

  validateSubmit = () => {
    let isValid = false;

    const { subscription, subscriptionValidate, legalValidate, individualValidate, contestValidate, isContestSelected } = this.state;

    if (subscription.invoice) {

      const isSubscriptionValid = checkValidationFields(subscriptionValidate);
      const islegalInvoiceValid = checkValidationFields(legalValidate);
      const isIndividualInvoiceValid = checkValidationFields(individualValidate);

      if (isContestSelected === 'true') {
        const isContestValid = checkValidationFields(contestValidate);
        const isInvoiceValid = subscription.selectInvoice === 'legal' ? islegalInvoiceValid : isIndividualInvoiceValid;

        isValid = isSubscriptionValid && isInvoiceValid && isContestValid;

      } else {
        const isInvoiceValid = subscription.selectInvoice === 'legal' ? islegalInvoiceValid : isIndividualInvoiceValid;
        isValid = isSubscriptionValid && isInvoiceValid;
      }

      return isValid;

    } else {
      const isSubscriptionValid = checkValidationFields(subscriptionValidate);
      const isContestValid = checkValidationFields(contestValidate);

      if (isContestSelected === 'true') {
        isValid = isSubscriptionValid && isContestValid;

      } else {
        isValid = isSubscriptionValid;
      }

      return isValid;
    }
  }

  handleDiscountPrice = () => {
    if (this.state.subsriptionPrice !== 0 && this.props.percentPrice !== 0) {

      const discountPrice = this.state.subsriptionPrice - (this.props.percentPrice / 100) * this.state.subsriptionPrice;
      this.setState({ percentDiscountPrice: discountPrice });
    }
  }

  render() {

    const { isSubscriptionOpen, loading, notifySubscriptions } = this.props;
    const { subscriptionFocus, subscription, legal, individual, legalFocus, individualFocus, contest, contestValidate, contestUpdate,
      legalValidate, legalUpdate, individualValidate, individualUpdate, subscriptionValidate, subsriptionPrice, percentDiscountPrice } = this.state;

    const isValid = this.validateSubmit();

    const notifySubscriptionSuccessPaymentTemplate = <Notify
      typeGroup={'Subscriptions'}
      type={notifySubscriptions.type}
      htmlTemplate={notifySubscriptions.htmlTemplate}
      active={notifySubscriptions.active}
      closeNotify={notifySubscriptions.closeNotify}
      messageHeading={notifySubscriptions.messageHeading}
      messageBody={notifySubscriptions.messageBody}
      messageError={notifySubscriptions.messageError}
      classStyle={'notify-white'}
      classPosition={'notify-absolute'} />

    return (
      <Modal
        classID={`addSubscription-main`}
        classMain={`addSubscription-main`}
        classWrapper={`addSubscription-wrapper`}
        classShell={`addSubscription-shell ${notifySubscriptions.htmlTemplate === "subscriptionsSuccess" ? 'modal-success-offset': ''}`}
        handleClose={this.handleClose}
        stateModal={isSubscriptionOpen}
        range={300}>

      {
        loading
          ? <Spinner classColor={`spinner-white`} />
          : null
      }
      {
        notifySubscriptions.htmlTemplate === "subscriptionsSuccess" || notifySubscriptions.htmlTemplate === "subscriptionsCatchError"
          ? notifySubscriptionSuccessPaymentTemplate
          : null
      }

      <p className="subscription-plus"><i className="fas fa-plus"></i></p>
      <h2 className="subscription-title">Добавяне на абонамент</h2>
      <p className="subscription-info">Моля изберете абонамент от посочените по-долу и начин на плащане.</p>

      <form className="add-subscription-form" id="add-subscription-form">

        <SubscriptionBody
          subscriptionFocus={subscriptionFocus}
          onFocus={(e) => this.handleFocus(e, 'subscription')}
          onBlur={(e) => this.handleFocusOut(e, 'subscription')}
          onChangeInfo={(e) => this.handleChange(e, 'subscription')}
          onChangeContestFields={(e) => this.handleChange(e, 'contest')}
          subscription={subscription}
          subscriptionValidate={subscriptionValidate}
          contest={contest}
          contestValidate={contestValidate}
          contestUpdate={contestUpdate} />
        {
          subscription.invoice
            ? <div className="invoice-content">
                <h3 className="invoice-title">Данни за фактура</h3>
                <InvoiceHeader
                  onChangeInfo={(e) => this.handleChange(e, 'subscription')}
                  selectInvoice={subscription.selectInvoice} />

                  {
                    subscription.selectInvoice === "legal"
                    ? <InvoiceLegal
                        onFocus={(e) => this.handleFocus(e, 'legal')}
                        onBlur={(e) => this.handleFocusOut(e, 'legal')}
                        onChangeInfo={(e) => this.handleChange(e, 'legal')}
                        legal={legal}
                        isFocus={legalFocus}
                        legalValidate={legalValidate}
                        legalUpdate={legalUpdate} />
                    : subscription.selectInvoice === "individual"
                      ? <InvoiceIndividual
                          onFocus={(e) => this.handleFocus(e, 'individual')}
                          onBlur={(e) => this.handleFocusOut(e, 'individual')}
                          onChangeInfo={(e) => this.handleChange(e, 'individual')}
                          individual={individual}
                          isFocus={individualFocus}
                          individualValidate={individualValidate}
                          individualUpdate={individualUpdate} />
                      : null
                  }
              </div>
            : null
        }

        <SubsriptionFooter onChangeInfo={(e) => this.handleChange(e, 'subscription')} subscriptionPayment={subscription.payment} />

        <fieldset className="form-field-set field-set-btn no-margin">

          {
            percentDiscountPrice !== 0
              ? <p className="subscription-price">Сума:<span className="subscription-amount"><del>{subsriptionPrice} лв.</del></span><span className="subscription-discount">{percentDiscountPrice} лв.</span></p>
              : <p className="subscription-price">Сума:<span className="subscription-amount">{subsriptionPrice}</span>лв.</p>
          }
          <button type="button" className="easy-math-button btn-yellow btn-form-size" value="Enter" title="Поръчай" disabled={!isValid} onClick={this.onSubmitHandler}>Поръчай</button>

        </fieldset>

      </form>
      </Modal>
    );
  }
}
const mapStateToProps = ({ authedUser, addSubscription, loading, notifications, modal }) => {

  return {
    userId: authedUser.userId,
    accessTokenExprirationTime: authedUser.accessTokenExprirationTime,
    refreshingTokenInPorgress: authedUser.refreshingTokenInPorgress,
    notifySubscriptions: notifications.subscriptions,
    successPayment: addSubscription.successPayment,
    percentPrice: addSubscription.percentPrice,
    isSubscriptionOpen: addSubscription.subscriptionModalState,
    isDiscountSuccessLoaded: addSubscription.isDiscountSuccessLoaded,
    promoCode: addSubscription.promoCode,
    loading: loading.subscriptionsLoading,
    isSubscriptionNotPaid: modal.isSubscriptionNotPaid
  };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
    ...actionsCreatorSubscription,
    ...actionsCreatorNotify,
    ...actionsAccountStats,
    ...actionsCreatorAuth,
    ...actionsCreatorModal
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSubscription);